<template>
  <div class="fixed top-4 left-4 z-50 max-w-md">
    <TransitionGroup name="fade">
      <div
        v-for="(log, index) of logs"
        :key="index"
        class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-2 shadow-lg break-words"
        role="alert"
      >
        <strong class="font-bold">Error: </strong>
        <span class="block sm:inline whitespace-normal">{{ log }}</span>
      </div>
    </TransitionGroup>
  </div>
  <div class="fixed top-4 left-4 z-50 max-w-md">
    <TransitionGroup name="fade">
      <div
        v-if="needUpdate"
        class="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-2 shadow-lg break-words"
        role="alert"
      >
        <strong class="font-bold">Версія CRM застаріла: </strong>
        <span class="block sm:inline whitespace-normal"
          >Для коректної роботи CRM оновіть сторінку</span
        >
      </div>
    </TransitionGroup>
  </div>
  <router-view></router-view>
</template>

<script>
import axios from "axios";
import { getDatabase, ref, get } from "firebase/database";
import { getFirestore, doc, setDoc } from "firebase/firestore";

import { uuid } from "vue-uuid";

const originalConsole = {
  log: console.log,
  error: console.error,
  warn: console.warn,
  info: console.info,
};

export default {
  name: "App",
  data() {
    return {
      logs: [],

      needUpdate: false,
    };
  },
  created() {
    document.title = "CEO Club CRM";

    if (window.origin.indexOf("ceoclubcrm") >= 0) {
      window.location.replace("https://crm.ceoclub.com.ua");
    }
  },
  mounted() {
    this.CheckWidth();

    var self = this;

    setTimeout(() => {
      window.addEventListener("resize", function () {
        self.CheckWidth();
      });
    }, 500);

    // Инициализация MutationObserver
    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function () {
        var dataTable = document.getElementsByClassName("data_table");

        if (dataTable) {
          dataTable = dataTable[0];

          if (dataTable) {
            dataTable.classList.remove("data_table_scrolled");

            var hasHorizontalScroll =
              document.body.scrollWidth > document.body.offsetWidth;

            if (hasHorizontalScroll) {
              dataTable.classList.add("data_table_scrolled");
            }
          }
        }
      });
    });

    // Настройки для MutationObserver
    var config = { childList: true, subtree: true };

    // Выбираем элемент для наблюдения (например, body)
    var targetNode = document.body;

    // Запуск наблюдения
    observer.observe(targetNode, config);

    // Вызов функции при загрузке страницы
    document.addEventListener("DOMContentLoaded", function () {
      var dataTable = document.getElementsByClassName("data_table");

      if (dataTable) {
        dataTable = dataTable[0];

        if (dataTable) {
          dataTable.classList.remove("data_table_scrolled");

          var hasHorizontalScroll =
            document.body.scrollWidth > document.body.offsetWidth;

          if (hasHorizontalScroll) {
            dataTable.classList.add("data_table_scrolled");
          }
        }
      }
    });

    document.addEventListener("mouseup", () => {
      var menus = document.getElementsByClassName(
        "vue-simple-context-menu--active"
      );

      for (let index = 0; index < menus.length; index++) {
        const menu = menus[index];

        menu.classList.remove("vue-simple-context-menu--active");
      }
    });

    // Переопределяем методы консоли

    // console.log = function () {
    //   self.SaveError("log", arguments);
    //   originalConsole.log.apply(console, arguments);
    // };

    console.error = function () {
      self.SaveError("error", arguments);
      originalConsole.error.apply(console, arguments);
    };

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        self.SaveError("error", {
          message: error.message,
          code: error.code,
          config: error.config,
          response: error.response ? error.response.data : null,
        });
        return Promise.reject(error);
      }
    );

    window.onerror = function (message, source, lineno, colno, error) {
      self.SaveError("error", { message, source, lineno, colno, error });
    };

    window.addEventListener("error", (event) => {
      self.SaveError("error", {
        message: event.message,
        source: event.filename,
        lineno: event.lineno,
        colno: event.colno,
        error: event.error ? event.error.toString() : "Unknown error",
      });
    });

    window.addEventListener("unhandledrejection", (event) => {
      self.SaveError("promise_rejection", event.reason);
    });

    // console.warn = function () {
    //   self.SaveError("warn", arguments);
    //   originalConsole.warn.apply(console, arguments);
    // };

    // console.info = function () {
    //   self.SaveError("info", arguments);
    //   originalConsole.info.apply(console, arguments);
    // };
  },
  beforeUnmount() {
    // Восстанавливаем оригинальные методы консоли
    // console.log = originalConsole.log;
    console.error = originalConsole.error;
    // console.warn = originalConsole.warn;
    // console.info = originalConsole.info;
  },
  methods: {
    CheckSession: function () {
      if (localStorage.webSessionId) {
        const db = getDatabase();
        const SessionRef = ref(db, "WebSessions/");
        get(SessionRef).then((Sessions) => {
          if (Sessions.exists()) {
            for (const [SessionId, Session] of Object.entries(Sessions.val())) {
              if (
                SessionId != undefined &&
                Session != undefined &&
                Session.id != undefined &&
                Session.id == localStorage.webSessionId
              ) {
                return;
              }
            }
          }

          localStorage.removeItem("webSessionId");

          this.$router.push({ name: "LogInPage" });
        });
      }
    },
    CheckWidth: function () {
      var dataTable = document.getElementsByClassName("data_table");

      if (dataTable) {
        dataTable = dataTable[0];

        if (dataTable) {
          dataTable.classList.remove("data_table_scrolled");

          var hasHorizontalScroll =
            document.body.scrollWidth > document.body.offsetWidth;

          if (hasHorizontalScroll) {
            dataTable.classList.add("data_table_scrolled");
          }
        }
      }
    },
    SaveError: async function (type, args) {
      try {
        const db = getFirestore();
        const id = uuid.v4();

        // Handle both array-like and object arguments
        let message;
        if (args.message) {
          // Handle error object format
          message = JSON.stringify({
            message: args.message,
            code: args.code,
            response: args.response,
            config: args.config,
          });
        } else {
          // Handle console arguments format
          message = Array.from(args)
            .map((arg) => {
              if (typeof arg === "object") {
                return JSON.stringify(arg);
              }
              return String(arg);
            })
            .join(" ");
        }

        const logData = {
          type,
          message,
          timestamp: new Date().toString(),
          userAgent: navigator.userAgent,
          url: window.location.href,
          userId: localStorage.webAdminId,
        };

        this.logs.push(logData.message);

        setTimeout(() => {
          this.logs = this.logs.filter((x) => x != logData.message);
        }, 8000);

        await setDoc(doc(db, "ConsoleLog", id), logData);
      } catch (error) {
        originalConsole.error("Ошибка сохранения в Firestore:", error);
      }
    },
  },
  watch: {
    "$route.name": function () {
      const db = getDatabase();
      const AppVersionRef = ref(db, "AppSettings/");

      get(AppVersionRef).then((AppVersion) => {
        if (AppVersion.exists()) {
          var CurrentVersion = AppVersion.val().webVersion;

          if (CurrentVersion != undefined && CurrentVersion != null) {
            if (CurrentVersion != "3.0.156") {
              this.needUpdate = true;

              setTimeout(() => {
                this.needUpdate = false;
              }, 8000);
            } else {
              this.needUpdate = false;
            }
          }
        }
      });

      //this.CheckSession();
      setTimeout(() => {
        this.CheckWidth();
      }, 500);
    },
  },
};
</script>

<style scoped src="@/../public/input.css"></style>

<style>
@import "vue-simple-context-menu/dist/vue-simple-context-menu.css";

@import "./styles_tailwind/styles.css";

@import "./styles_v2/slider.css";

@import "./styles/InputStyles.css";
@import "./styles/TextStyles.css";

@import "./styles_v2/text.css";
@import "./styles_v2/color.css";
@import "./styles_v2/menu_item.css";
@import "./styles_v2/tabs.css";
@import "./styles_v2/form.css";
@import "./styles_v2/checkbox.css";
@import "./styles_v2/toggle.css";
@import "./styles_v2/context_menu.css";
@import "./styles_v2/context_menu_icons.css";
@import "./styles_v2/rating.css";
@import "./styles_v2/check_area.css";
@import "./styles_v2/check_button.css";
@import "./styles_v2/table.css";
@import "./styles_v2/page.css";
@import "./styles_v2/analytic_page.css";
@import "./styles_v2/badge.css";
@import "./styles_v2/inputs.css";
@import "./styles_v2/buttons.css";
@import "./styles_v2/avatars.css";
@import "./styles_v3/calendar.css";
@import "./styles_v2/modal_view.css";
@import "./styles_v2/popup_modal_view.css";
@import "./styles_v2/notification.css";
@import "./styles_v2/file_upload.css";
@import "./styles_v2/loader.css";
@import "./styles_v2/filters.css";
@import "./styles_v2/images.css";
@import "./styles_v2/range_slider.css";
@import "./styles_v2/collapse.css";
@import "./styles_v2/phone.css";

@import "./styles_v2/load_panel.css";

@import "./styles_v2/chart.css";

@import "./styles_v2/home.css";

@import "./styles_v2_mobile/page.css";
@import "./styles_v2_mobile/analytic_page.css";
@import "./styles_v2_mobile/filters.css";
@import "./styles_v2_mobile/table.css";
@import "./styles_v2_mobile/loader.css";
@import "./styles_v2_mobile/modal_view.css";
@import "./styles_v2_mobile/changed_elements.css";
@import "./styles_v2_mobile/calendar.css";
@import "./styles_v2_mobile/chart.css";

@import "./styles/DataTable.css";
@import "./styles/EditChaptersView.css";
@import "./styles/InputStyles.css";
@import "./styles/ModalView.css";
@import "./styles/SubModalView.css";
@import "./styles/TextStyles.css";

@import "./styles_tailwind/primitives.css";
@import "./styles_tailwind/light_theme_tokens.css";

@import "./assets/fonts/Formular/fonts-formular.css";
@import "./assets/fonts/Inter/fonts-inter.css";

html,
body,
#app {
  width: 100%;

  height: fit-content;
  min-height: 100dvh;

  margin: 0px;

  transition: all 0s;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  transition: all 0.2s ease;

  -webkit-tap-highlight-color: transparent;
}

*:hover {
  transition: all 0.2s ease;
}

.Grabbing * {
  cursor: grabbing !important;
}

.tippy-box[data-theme~="ceo"] {
  font-family: "Formular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  letter-spacing: -0.28px;

  display: flex;
  align-items: center;

  padding: 8px;

  background: #222124;
  border-radius: 4px;

  z-index: 99999;
}

.tippy-box[data-theme~="ceo"] > .tippy-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 4px;
}

.tippy-box[data-theme~="ceo"] > .tippy-content span {
  color: var(--base-colors-400, #898ca7);
}

.tippy-box[data-theme~="ceo"][data-placement^="top"] > .tippy-arrow {
  transition: 0s !important;
}

.tippy-box[data-theme~="ceo"][data-placement^="left"] > .tippy-arrow {
  transition: 0s !important;
}

.tippy-box[data-theme~="ceo"][data-placement^="top"] > .tippy-arrow::before {
  border-top-color: #222124;
}

.tippy-box[data-theme~="ceo"][data-placement^="bottom"] > .tippy-arrow::before {
  border-bottom-color: #222124;
}

.tippy-box[data-theme~="ceo"][data-placement^="left"] > .tippy-arrow::before {
  border-left-color: #222124;
}

.tippy-box[data-theme~="ceo"][data-placement^="right"] > .tippy-arrow::before {
  border-right-color: #222124;
}

.tippy-box[data-theme~="ceo_ui_kit"] {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  display: flex;
  align-items: center;

  padding: 8px 12px;

  background: var(--color-background-tooltip-body);
  border-radius: 8px;

  z-index: 99999;
}

.tippy-box[data-theme~="ceo_ui_kit"] > .tippy-svg-arrow {
  transition: 0ms !important;
}

.tippy-box[data-theme~="ceo_ui_kit"][data-placement^="top"]
  > .tippy-svg-arrow
  > svg {
  transform: rotate(0deg) !important;
  margin-top: -0.5px;
}

.tippy-box[data-theme~="ceo_ui_kit"][data-placement^="bottom"]
  > .tippy-svg-arrow
  > svg {
  transform: rotate(180deg) !important;
  margin-bottom: -0.5px;
}

.tippy-box[data-theme~="ceo_ui_kit"][data-placement^="left"]
  > .tippy-svg-arrow
  > svg {
  transform: rotate(270deg) !important;
  margin-left: -3.2px;
}

.tippy-box[data-theme~="ceo_ui_kit"][data-placement^="right"]
  > .tippy-svg-arrow
  > svg {
  transform: rotate(90deg) !important;
  margin-right: -3.2px;
}

.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
