import { defineAsyncComponent } from "vue";

export const ProfilePage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/ProfilePage.vue")
);

export const IntegrationPage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/IntegrationPage.vue")
);

export const ActivitiesPage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/ActivitiesPage.vue")
);

export const DataPage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/DataPage.vue")
);

export const ProcessesPage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/ProcessesPage.vue")
);

export const AnalyticsPage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/AnalyticsPage.vue")
);

export const InfoPage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/InfoPage.vue")
);

export const EventsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/activities_sub_page/EventsPage.vue"
  )
);

export const SupportsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/activities_sub_page/SupportsPage.vue"
  )
);

export const CharitiesPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/activities_sub_page/CharitiesPage.vue"
  )
);

export const ContributionsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/activities_sub_page/ContributionsPage.vue"
  )
);

export const OpportunitiesPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/activities_sub_page/OpportunitiesPage.vue"
  )
);

export const FuruteEvents_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/activities_sub_page/events_sub_page/FuruteEvents.vue"
  )
);

export const PastEvents_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/activities_sub_page/events_sub_page/PastEvents.vue"
  )
);

export const VisibilityEventsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/activities_sub_page/events_sub_page/VisibilityEventsPage.vue"
  )
);

export const AllSupports_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/activities_sub_page/supports_sub_page/AllSupports.vue"
  )
);

export const ProvidedSupports_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/activities_sub_page/supports_sub_page/ProvidedSupports.vue"
  )
);

export const ReceivedSupports_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/activities_sub_page/supports_sub_page/ReceivedSupports.vue"
  )
);

export const GroupsPage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/data_sub_page/GroupsPage.vue")
);

export const SegmentsPage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/data_sub_page/SegmentsPage.vue")
);

export const ChatsPage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/data_sub_page/ChatsPage.vue")
);

export const VideosPage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/data_sub_page/VideosPage.vue")
);

export const CollaborationsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/data_sub_page/CollaborationsPage.vue"
  )
);

export const RecommendationsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/data_sub_page/RecommendationsPage.vue"
  )
);

export const ExpertisesPage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/data_sub_page/ExpertisesPage.vue")
);

export const EngagementPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/analytics_sub_page/EngagementPage.vue"
  )
);

export const VisitedEventsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/analytics_sub_page/VisitedEventsPage.vue"
  )
);

export const ApplicationPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/analytics_sub_page/ApplicationPage.vue"
  )
);

export const CreatorsVideosPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/data_sub_page/videos_sub_page/CreatorsVideosPage.vue"
  )
);

export const VisibilityVideosPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/data_sub_page/videos_sub_page/VisibilityVideosPage.vue"
  )
);

export const CreatorsRecommendationsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/data_sub_page/recommendations_sub_page/CreatorsRecommendationsPage.vue"
  )
);

export const VisibilityRecommendationsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/data_sub_page/recommendations_sub_page/VisibilityRecommendationsPage.vue"
  )
);

export const CreatorsCollaborationsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/data_sub_page/collaborations_sub_page/CreatorsCollaborationsPage.vue"
  )
);

export const VisibilityCollaborationsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/data_sub_page/collaborations_sub_page/VisibilityCollaborationsPage.vue"
  )
);

export const Analytic_GeneralPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/analytics_sub_page/engagement_sub_page/GeneralPage.vue"
  )
);

export const Analytic_EventsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/analytics_sub_page/engagement_sub_page/EventsPage.vue"
  )
);

export const Analytic_SupportsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/analytics_sub_page/engagement_sub_page/SupportsPage.vue"
  )
);

export const Analytic_CharitiesPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/analytics_sub_page/engagement_sub_page/CharitiesPage.vue"
  )
);

export const Analytic_ContributionsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/analytics_sub_page/engagement_sub_page/ContributionsPage.vue"
  )
);

export const Analytic_OpportunitiesPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/analytics_sub_page/engagement_sub_page/OpportunitiesPage.vue"
  )
);

export const LoginsPage_V3 = defineAsyncComponent(() =>
  import(
    "@/pages_v3/users/profile_sub_pages/analytics_sub_page/application_sub_page/LoginsPage.vue"
  )
);

export const PrivatePage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/infos_sub_page/PrivatePage.vue")
);

export const PublicPage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/infos_sub_page/PublicPage.vue")
);

export const HistoryPage_V3 = defineAsyncComponent(() =>
  import("@/pages_v3/users/profile_sub_pages/infos_sub_page/HistoryPage.vue")
);
