<template>
  <div class="modal_view">
    <div class="modal_view_background" @click="CloseWindow"></div>
    <transition name="modal_view_small_slide">
      <div
        class="modal_view_window modal_view_window_small"
        ref="window"
        v-if="Loaded"
      >
        <div class="modal_view_header" :class="{ compressed: HideHeader }">
          <div class="text_2xl modal_view_header_title">Фільтри</div>
          <div class="modal_view_header_exit">
            <div
              class="button image_button button_noborder"
              @click="CloseWindow"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.5303 8.53033C16.8232 8.23744 16.8232 7.76256 16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967L12 10.9393L8.53033 7.46967C8.23744 7.17678 7.76256 7.17678 7.46967 7.46967C7.17678 7.76256 7.17678 8.23744 7.46967 8.53033L10.9393 12L7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23744 16.8232 8.53033 16.5303L12 13.0607L15.4697 16.5303C15.7626 16.8232 16.2374 16.8232 16.5303 16.5303C16.8232 16.2374 16.8232 15.7626 16.5303 15.4697L13.0607 12L16.5303 8.53033Z"
                  fill="#121217"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="modal_view_content" ref="content" @scroll="Scrolling">
          <div class="modal_view_content_block">
            <div
              class="text_xs section_dropdown"
              @click="AdminsBlockCheck = !AdminsBlockCheck"
              :class="{ section_dropdown_open: AdminsBlockCheck }"
            >
              КМ
            </div>
            <Collapse class="modal_view_content_block" :when="AdminsBlockCheck">
              <div class="modal_view_content_block">
                <div
                  class="field"
                  style="width: calc(100% - 2px)"
                  v-if="
                    SearchAdminText.length > 0 ||
                    Admins.filter(
                      (x) =>
                        (
                          x.firstName.toLowerCase() +
                          ' ' +
                          x.lastName.toLowerCase()
                        ).indexOf(this.SearchAdminText.toLowerCase()) >= 0 &&
                        Processes.filter(
                          (v) =>
                            v.communityManagers &&
                            v.communityManagers.find((y) => y == x.id) !=
                              undefined
                        ).length > 0
                    ).length > 6
                  "
                >
                  <div class="field_input">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                        stroke="#B3B5C6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <input
                      placeholder="Пошук..."
                      v-model="SearchAdminText"
                      class="text_xs"
                    />
                    <svg
                      v-if="SearchAdminText.length > 0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      class="field_input_clear"
                      @click="SearchAdminText = ''"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.5303 8.53033C16.8232 8.23744 16.8232 7.76256 16.5303 7.46967C16.2374 7.17678 15.7626 7.17678 15.4697 7.46967L12 10.9393L8.53033 7.46967C8.23744 7.17678 7.76256 7.17678 7.46967 7.46967C7.17678 7.76256 7.17678 8.23744 7.46967 8.53033L10.9393 12L7.46967 15.4697C7.17678 15.7626 7.17678 16.2374 7.46967 16.5303C7.76256 16.8232 8.23744 16.8232 8.53033 16.5303L12 13.0607L15.4697 16.5303C15.7626 16.8232 16.2374 16.8232 16.5303 16.5303C16.8232 16.2374 16.8232 15.7626 16.5303 15.4697L13.0607 12L16.5303 8.53033Z"
                        fill="#121217"
                      />
                    </svg>
                  </div>
                </div>
                <div class="filter_items_block">
                  <div
                    class="filter_item"
                    style="
                      padding-bottom: 12px;
                      border-bottom: 1px solid #ededf1;
                    "
                    @click="SelectAllAdmins"
                    v-if="
                      Admins.filter(
                        (x) =>
                          (
                            x.firstName.toLowerCase() +
                            ' ' +
                            x.lastName.toLowerCase()
                          ).indexOf(this.SearchAdminText.toLowerCase()) >= 0 &&
                          Processes.filter(
                            (v) =>
                              FilterByTypes(v) &&
                              v.communityManagers &&
                              v.communityManagers.find((y) => y == x.id) !=
                                undefined
                          ).length > 0
                      ).length >= 6
                    "
                  >
                    <div
                      class="checkbox"
                      :class="{
                        checkbox_checked: CheckAllAdmins(),
                        checkbox_indeterminate:
                          SelectedAdmins.length > 0 &&
                          !CheckAllAdmins() &&
                          CheckSomeAdmins(),
                      }"
                    ></div>

                    <span
                      class="text_xs"
                      style="margin-right: auto; color: #6b6e8c"
                    >
                      Вибрати все</span
                    >
                  </div>
                  <div
                    class="filter_item"
                    v-for="Admin of Admins.filter(
                      (x) =>
                        (
                          x.firstName.toLowerCase() +
                          ' ' +
                          x.lastName.toLowerCase()
                        ).indexOf(this.SearchAdminText.toLowerCase()) >= 0 &&
                        Processes.filter(
                          (v) =>
                            FilterByTypes(v) &&
                            v.communityManagers &&
                            v.communityManagers.find((y) => y == x.id) !=
                              undefined
                        ).length > 0
                    )"
                    :key="Admin.id"
                    @click="SelectAdmin(Admin)"
                  >
                    <div
                      class="checkbox"
                      :class="{
                        checkbox_checked:
                          SelectedAdmins.find((x) => x.id == Admin.id) !=
                          undefined,
                      }"
                    ></div>
                    <img
                      v-if="
                        Admin.photo &&
                        Admin.photo.length > 0 &&
                        Admin.photo.indexOf('data:image/svg+xml') >= 0
                      "
                      class="avatar avatar_s"
                      :src="Admin.photo"
                    />
                    <img
                      v-else-if="Admin.photo && Admin.photo.length > 0"
                      class="avatar avatar_s"
                      :src="
                        'https://firebasestorage.googleapis.com/v0/b/ceoclubapi.appspot.com/o/images%2F' +
                        Admin.id +
                        '%2Foriginal_48x48?alt=media'
                      "
                    />
                    <span class="text_xs" style="margin-right: auto">
                      {{ Admin.firstName + " " + Admin.lastName }}</span
                    >
                    <div
                      class="text_2xs result_count"
                      :class="{
                        result_count_active:
                          SelectedAdmins.find((x) => x.id == Admin.id) !=
                          undefined,
                      }"
                    >
                      {{
                        Processes.filter(
                          (x) =>
                            FilterByTypes(x) &&
                            x.communityManagers &&
                            x.communityManagers.find((y) => y == Admin.id) !=
                              undefined
                        ).length
                      }}
                    </div>
                  </div>
                  <div
                    class="filter_item"
                    @click="SelectAdmin({ id: 999 })"
                    v-if="
                      Processes.filter(
                        (v) =>
                          v.communityManagers == undefined ||
                          v.communityManagers.length == 0
                      ).length > 0
                    "
                  >
                    <div
                      class="checkbox"
                      :class="{
                        checkbox_checked:
                          SelectedAdmins.find((x) => x.id == 999) != undefined,
                      }"
                    ></div>
                    <span class="text_xs" style="margin-right: auto">
                      КМ не призначен</span
                    >
                    <div
                      class="text_2xs result_count"
                      :class="{
                        result_count_active:
                          SelectedAdmins.find((x) => x.id == 999) != undefined,
                      }"
                    >
                      {{
                        Processes.filter(
                          (x) =>
                            x.communityManagers == undefined ||
                            x.communityManagers.length == 0
                        ).length
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
            <div
              class="text_xs section_dropdown"
              @click="TypesBlockCheck = !TypesBlockCheck"
              :class="{ section_dropdown_open: TypesBlockCheck }"
            >
              Статус членства
            </div>
            <Collapse class="modal_view_content_block" :when="TypesBlockCheck">
              <div class="modal_view_content_block">
                <div class="filter_items_block">
                  <div
                    class="filter_item"
                    @click="SelectType({ id: 2 })"
                    v-if="
                      Processes.filter((x) => FilterByAdmins(x) && x.type == 2)
                        .length > 0
                    "
                  >
                    <div
                      class="checkbox"
                      :class="{
                        checkbox_checked:
                          SelectedTypes.find((x) => x.id == 2) != undefined,
                      }"
                    ></div>
                    <span class="text_xs" style="margin-right: auto">
                      Кандидат</span
                    >
                    <div
                      class="text_2xs result_count"
                      :class="{
                        result_count_active:
                          SelectedTypes.find((x) => x.id == 2) != undefined,
                      }"
                    >
                      {{
                        Processes.filter(
                          (x) => FilterByAdmins(x) && x.type == 2
                        ).length
                      }}
                    </div>
                  </div>
                  <div
                    class="filter_item"
                    @click="SelectType({ id: 1 })"
                    v-if="
                      Processes.filter((x) => FilterByAdmins(x) && x.type == 1)
                        .length > 0
                    "
                  >
                    <div
                      class="checkbox"
                      :class="{
                        checkbox_checked:
                          SelectedTypes.find((x) => x.id == 1) != undefined,
                      }"
                    ></div>
                    <span class="text_xs" style="margin-right: auto">
                      Член клубу</span
                    >
                    <div
                      class="text_2xs result_count"
                      :class="{
                        result_count_active:
                          SelectedTypes.find((x) => x.id == 1) != undefined,
                      }"
                    >
                      {{
                        Processes.filter(
                          (x) => FilterByAdmins(x) && x.type == 1
                        ).length
                      }}
                    </div>
                  </div>
                  <div
                    class="filter_item"
                    @click="SelectType({ id: 3 })"
                    v-if="
                      Processes.filter((x) => FilterByAdmins(x) && x.type == 3)
                        .length > 0
                    "
                  >
                    <div
                      class="checkbox"
                      :class="{
                        checkbox_checked:
                          SelectedTypes.find((x) => x.id == 3) != undefined,
                      }"
                    ></div>
                    <span class="text_xs" style="margin-right: auto">
                      Провідний член клубу</span
                    >
                    <div
                      class="text_2xs result_count"
                      :class="{
                        result_count_active:
                          SelectedTypes.find((x) => x.id == 3) != undefined,
                      }"
                    >
                      {{
                        Processes.filter(
                          (x) => FilterByAdmins(x) && x.type == 3
                        ).length
                      }}
                    </div>
                  </div>
                  <div
                    class="filter_item"
                    @click="SelectType({ id: 4 })"
                    v-if="
                      Processes.filter((x) => FilterByAdmins(x) && x.type == 4)
                        .length > 0
                    "
                  >
                    <div
                      class="checkbox"
                      :class="{
                        checkbox_checked:
                          SelectedTypes.find((x) => x.id == 4) != undefined,
                      }"
                    ></div>
                    <span class="text_xs" style="margin-right: auto">
                      Співвласник</span
                    >
                    <div
                      class="text_2xs result_count"
                      :class="{
                        result_count_active:
                          SelectedTypes.find((x) => x.id == 4) != undefined,
                      }"
                    >
                      {{
                        Processes.filter(
                          (x) => FilterByAdmins(x) && x.type == 4
                        ).length
                      }}
                    </div>
                  </div>
                  <div
                    class="filter_item"
                    @click="SelectType({ id: 5 })"
                    v-if="
                      Processes.filter((x) => FilterByAdmins(x) && x.type == 5)
                        .length > 0
                    "
                  >
                    <div
                      class="checkbox"
                      :class="{
                        checkbox_checked:
                          SelectedTypes.find((x) => x.id == 5) != undefined,
                      }"
                    ></div>
                    <span class="text_xs" style="margin-right: auto">
                      Гость</span
                    >
                    <div
                      class="text_2xs result_count"
                      :class="{
                        result_count_active:
                          SelectedTypes.find((x) => x.id == 5) != undefined,
                      }"
                    >
                      {{
                        Processes.filter(
                          (x) => FilterByAdmins(x) && x.type == 5
                        ).length
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div class="modal_view_footer" :class="{ compressed: HideHeader }">
          <div
            class="text_xs button button_secondary"
            style="width: calc(50% - 38px)"
            @click="ResetFilters"
          >
            Скинути
          </div>
          <div
            class="text_xs button button_primary"
            style="width: calc(50% - 38px)"
            @click="CloseWindow"
          >
            Результат ({{
              Processes.filter((x) => FilterByAdmins(x) && FilterByTypes(x))
                .length
            }})
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Collapse } from "vue-collapsed";

export default {
  props: {
    LoadProcesses: Array,
    Admins: Array,
    LoadSelectedAdmins: Array,
    LoadSelectedTypes: Array,
  },
  components: {
    Collapse,
  },
  data() {
    return {
      Loaded: false,

      AdminsBlockCheck: true,
      TypesBlockCheck: true,

      SearchAdminText: "",
      SelectedAdmins: [],

      SelectedTypes: [],

      HideHeader: false,
      ScrollTop: 0,
      ScrollWait: false,

      StopClick: false,
    };
  },
  mounted() {
    this.Loaded = true;

    if (this.LoadSelectedAdmins) {
      this.SelectedAdmins = JSON.parse(JSON.stringify(this.LoadSelectedAdmins));
    }
    if (this.LoadSelectedTypes) {
      this.SelectedTypes = JSON.parse(JSON.stringify(this.LoadSelectedTypes));
    }

    if (this.LoadProcesses) {
      this.Processes = JSON.parse(JSON.stringify(this.LoadProcesses));
    }
  },
  methods: {
    Scrolling: function () {
      try {
        var st = this.$refs.content.scrollTop;
        if (st < 20) {
          return;
        }

        if (this.ScrollWait == false) {
          if (
            st > this.ScrollTop &&
            this.$refs.content.scrollHeight - 65 >
              this.$refs.window.offsetHeight
          ) {
            this.HideHeader = true;
            this.ScrollWait = true;
            setTimeout(() => {
              this.ScrollWait = false;
            }, 200);
          } else if (st < this.ScrollTop) {
            this.HideHeader = false;
          }
        }

        this.ScrollTop = st;
      } catch (error) {
        console.log(error);
      }
    },
    CloseWindow: function () {
      this.Loaded = false;
      setTimeout(() => {
        this.$emit("CloseWindow");
      }, 400);
    },
    ResetFilters: function () {
      if (this.StopClick == false) {
        this.StopClick = true;

        this.SelectedAdmins = [];
        this.SelectedTypes = [];

        this.$emit("SetAdminFilters", []);
        this.$emit("SetTypeFilters", []);

        setTimeout(() => {
          this.StopClick = false;
        }, 100);

        this.CloseWindow();
      }
    },
    SelectAdmin: function (Admin) {
      if (this.StopClick == false) {
        this.StopClick = true;

        if (this.SelectedAdmins.find((x) => x.id == Admin.id)) {
          this.SelectedAdmins = this.SelectedAdmins.filter(
            (x) => x.id != Admin.id
          );
        } else {
          this.SelectedAdmins.push(Admin);
        }

        this.$emit("SetAdminFilters", this.SelectedAdmins);

        setTimeout(() => {
          this.StopClick = false;
        }, 100);
      }
    },
    SelectType: function (Type) {
      if (this.StopClick == false) {
        this.StopClick = true;

        if (this.SelectedTypes.find((x) => x.id == Type.id)) {
          this.SelectedTypes = this.SelectedTypes.filter(
            (x) => x.id != Type.id
          );
        } else {
          this.SelectedTypes.push(Type);
        }

        this.$emit("SetTypeFilters", this.SelectedTypes);

        setTimeout(() => {
          this.StopClick = false;
        }, 100);
      }
    },
    SelectAllAdmins: function () {
      var tempAdmins = JSON.parse(
        JSON.stringify(
          this.Admins.filter(
            (x) =>
              (
                x.firstName.toLowerCase() +
                " " +
                x.lastName.toLowerCase()
              ).indexOf(this.SearchAdminText.toLowerCase()) >= 0 &&
              this.Processes.filter(
                (v) =>
                  v.communityManagers &&
                  v.communityManagers.find((y) => y == x.id) != undefined
              ).length > 0
          )
        )
      );

      tempAdmins.push({ id: 999 });

      if (this.SelectedAdmins.length == tempAdmins.length) {
        this.SelectedAdmins = [];
      } else {
        this.SelectedAdmins = JSON.parse(JSON.stringify(tempAdmins));
      }

      this.$emit("SetAdminFilters", this.SelectedAdmins);
    },
    CheckAllAdmins: function () {
      var tempAdmins = JSON.parse(
        JSON.stringify(
          this.Admins.filter(
            (x) =>
              (
                x.firstName.toLowerCase() +
                " " +
                x.lastName.toLowerCase()
              ).indexOf(this.SearchAdminText.toLowerCase()) >= 0 &&
              this.Processes.filter(
                (v) =>
                  v.communityManagers &&
                  v.communityManagers.find((y) => y == x.id) != undefined
              ).length > 0
          )
        )
      );

      tempAdmins.push({ id: 999 });

      var check = true;

      tempAdmins.forEach((tempAdmin) => {
        if (
          this.SelectedAdmins.find((x) => x.id == tempAdmin.id) == undefined
        ) {
          check = false;
        }
      });

      return check;
    },
    CheckSomeAdmins: function () {
      var tempAdmins = JSON.parse(
        JSON.stringify(
          this.Admins.filter(
            (x) =>
              (
                x.firstName.toLowerCase() +
                " " +
                x.lastName.toLowerCase()
              ).indexOf(this.SearchAdminText.toLowerCase()) >= 0 &&
              this.Processes.filter(
                (v) =>
                  v.communityManagers &&
                  v.communityManagers.find((y) => y == x.id) != undefined
              ).length > 0
          )
        )
      );

      tempAdmins.push({ id: 999 });

      var check = false;

      tempAdmins.forEach((tempAdmin) => {
        if (
          this.SelectedAdmins.find((x) => x.id == tempAdmin.id) == undefined
        ) {
          check = true;
        }
      });

      return check;
    },
    FilterByAdmins: function (event) {
      if (this.SelectedAdmins.length > 0) {
        var check = false;

        if (event.communityManagers && event.communityManagers.length > 0) {
          this.SelectedAdmins.forEach((admin) => {
            if (
              event.communityManagers.find((x) => x == admin.id) != undefined
            ) {
              check = true;
            }
          });
        } else if (this.SelectedAdmins.find((x) => x.id == 999)) {
          check = true;
        }

        return check;
      }
      return true;
    },
    FilterByTypes: function (user) {
      if (this.SelectedTypes.length > 0) {
        var check = false;

        this.SelectedTypes.forEach((type) => {
          if (user.type == type.id) {
            check = true;
          }
        });

        return check;
      }
      return true;
    },
  },
  watch: {
    LoadProcesses: {
      handler: async function () {
        this.Processes = JSON.parse(JSON.stringify(this.LoadProcesses));
      },
      deep: true,
    },
  },
};
</script>

<style></style>
