import { createRouter, createWebHistory } from "vue-router";

import LogInPage from "@/pages/AuthenticationPage.vue";

const MainPage = () => import("@/pages/MainPage.vue");

const DataPage = () => import("@/pages/Data/DataPage.vue");
const UsersPage = () => import("@/pages/Data/UsersPage.vue");
const GuestsPage = () => import("@/pages/Data/GuestsPage.vue");
const GroupsPage = () => import("@/pages/Data/GroupsPage.vue");
const SegmentsPage = () => import("@/pages/Data/SegmentsPage.vue");
const EventsPage = () => import("@/pages/Data/EventsPage.vue");
const ChatsPage = () => import("@/pages/Data/ChatsPage.vue");
const VideosPage = () => import("@/pages/Data/VideosPage.vue");
const ExpertsPage = () => import("@/pages/Data/ExpertsPage.vue");
const CollaborationsPage = () => import("@/pages/Data/CollaborationsPage.vue");
const RecommendationsPage = () =>
  import("@/pages/Data/RecommendationsPage.vue");
const CodexPage = () => import("@/pages/Data/CodexPage.vue");
const InitiativePage = () => import("@/pages/Data/InitiativePage.vue");
const ExpertisesPage = () => import("@/pages/Data/ExpertisesPage.vue");

const ActivityPage = () => import("@/pages/Activity/ActivityPage.vue");
const RegistrationsPage = () => import("@/pages/Activity/InvitesPage.vue");
const TasksPage = () => import("@/pages/Activity/TasksPage.vue");
const ContributionsPage = () =>
  import("@/pages/Activity/ContributionsPage.vue");
const CharitysPage = () => import("@/pages/Activity/CharitysPage.vue");
const SupportsPage = () => import("@/pages/Activity/SupportsPage.vue");
const PossibilitysPage = () => import("@/pages/Activity/PossibilitysPage.vue");

const AnalyticsPage = () => import("@/pages/Analytics/AnalyticsPage.vue");
const ActivityPage_Analytics = () =>
  import("@/pages/Analytics/ActivityPage.vue");
const AttendanceGroupPage = () =>
  import("@/pages/Analytics/AttendanceGroupPage.vue");
const InvolvementPage = () => import("@/pages/Analytics/InvolvementPage.vue");
const AttendancePage = () => import("@/pages/Analytics/AttendancePage.vue");
const AnalyticContributionsPage = () =>
  import("@/pages/Analytics/ContributionsPage.vue");
const AnalyticSupportsPage = () => import("@/pages/Analytics/SupportsPage.vue");
const AnalyticCharitysPage = () => import("@/pages/Analytics/CharitysPage.vue");
const AnalyticPossibilitysPage = () =>
  import("@/pages/Analytics/PossibilitysPage.vue");
const ParticipantFlowPage = () =>
  import("@/pages/Analytics/ParticipantFlowPage.vue");

import ProcessesPage from "@/pages/Processes/ProcessesPage.vue";
import ProcessPage from "@/pages/Processes/ProcessPage.vue";

const ResetPasswordPage = () => import("@/pages/App/ResetPasswordPage.vue");
const GoToAppPage = () => import("@/pages/App/GoToAppPage.vue");
const GoToAppSecondPage = () => import("@/pages/App/GoToAppSecondPage.vue");
const GoToAppThirdPage = () => import("@/pages/App/GoToAppThirdPage.vue");
const RegistrationManagerPage = () =>
  import("@/pages/App/RegistrationManagerPage.vue");

const FavoritesPage = () => import("@/pages/Favorites/FavoritesPage.vue");

const HomePage = () => import("@/pages/HomePage.vue");

const IntegrationsPage = () =>
  import("@/pages/Integrations/IntegrationsPage.vue");

const LayoutPage = () => import("@/pages/LayoutPage.vue");

const MainPage_V3 = () => import("@/pages_v3/MainPage.vue");
const RealtimeHistory_V3 = () => import("@/pages_v3/RealtimeHistory.vue");

const EventProfilePage_V3 = () => import("@/pages_v3/events/ProfilePage.vue");

const InvitesPage_V3 = () =>
  import("@/pages_v3/events/profile_sub_pages/InvitesPage.vue");

const GuestsListPage_V3 = () =>
  import(
    "@/pages_v3/events/profile_sub_pages/invites_sub_pages/GuestsList.vue"
  );
const UsersListPage_V3 = () =>
  import("@/pages_v3/events/profile_sub_pages/invites_sub_pages/UsersList.vue");

import {
  ProfilePage_V3,
  IntegrationPage_V3,
  ActivitiesPage_V3,
  DataPage_V3,
  ProcessesPage_V3,
  AnalyticsPage_V3,
  InfoPage_V3,
  EventsPage_V3,
  SupportsPage_V3,
  CharitiesPage_V3,
  ContributionsPage_V3,
  OpportunitiesPage_V3,
  FuruteEvents_V3,
  PastEvents_V3,
  VisibilityEventsPage_V3,
  AllSupports_V3,
  ProvidedSupports_V3,
  ReceivedSupports_V3,
  GroupsPage_V3,
  SegmentsPage_V3,
  ChatsPage_V3,
  VideosPage_V3,
  CollaborationsPage_V3,
  RecommendationsPage_V3,
  ExpertisesPage_V3,
  EngagementPage_V3,
  VisitedEventsPage_V3,
  ApplicationPage_V3,
  CreatorsVideosPage_V3,
  VisibilityVideosPage_V3,
  CreatorsRecommendationsPage_V3,
  VisibilityRecommendationsPage_V3,
  CreatorsCollaborationsPage_V3,
  VisibilityCollaborationsPage_V3,
  Analytic_GeneralPage_V3,
  Analytic_EventsPage_V3,
  Analytic_SupportsPage_V3,
  Analytic_CharitiesPage_V3,
  Analytic_ContributionsPage_V3,
  Analytic_OpportunitiesPage_V3,
  LoginsPage_V3,
  PrivatePage_V3,
  PublicPage_V3,
  HistoryPage_V3,
} from "@/page_loaders/user_profile_loader.js";

export default createRouter({
  mode: "hash",
  base: process.env.VUE_ROUTER_BASE,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  history: createWebHistory(),
  routes: [
    {
      path: "/:id?",
      name: "GoToAppPage",
      component: GoToAppPage,
    },
    {
      path: "/:pathId?/:id?",
      name: "GoToAppSecondPage",
      component: GoToAppSecondPage,
    },
    {
      path: "/:pathId?/:pathId2?/:id?",
      name: "GoToAppThirdPage",
      component: GoToAppThirdPage,
    },
    {
      path: "/",
      redirect: "home",
      name: "MainPage",
      component: MainPage,
      children: [
        {
          path: "/action_history",
          name: "RealtimeHistory_V3",
          component: RealtimeHistory_V3,
        },
        {
          path: "/v2",
          name: "MainPage_V3",
          redirect: { name: "ProfilePage_V3" },
          component: MainPage_V3,
          children: [
            {
              path: "profile/:id?",
              name: "ProfilePage_V3",
              redirect: { name: "ActivitiesPage_V3" },
              component: ProfilePage_V3,
              children: [
                {
                  path: "vzayemovidnosini",
                  name: "IntegrationPage_V3",
                  component: IntegrationPage_V3,
                },
                {
                  path: "zaluchenist",
                  name: "ActivitiesPage_V3",
                  redirect: { name: "EventsPage_V3" },
                  component: ActivitiesPage_V3,
                  children: [
                    {
                      path: "zustrichi",
                      name: "EventsPage_V3",
                      redirect: { name: "FuruteEvents_V3" },
                      component: EventsPage_V3,
                      children: [
                        {
                          path: "majbutni",
                          name: "FuruteEvents_V3",
                          component: FuruteEvents_V3,
                        },
                        {
                          path: "minuli",
                          name: "PastEvents_V3",
                          component: PastEvents_V3,
                        },
                        {
                          path: "vidimist",
                          name: "VisibilityEventsPage_V3",
                          component: VisibilityEventsPage_V3,
                        },
                      ],
                    },
                    {
                      path: "vzayemodopomogi",
                      name: "SupportsPage_V3",
                      component: SupportsPage_V3,
                      redirect: { name: "AllSupports_V3" },
                      children: [
                        {
                          path: "vsi",
                          name: "AllSupports_V3",
                          component: AllSupports_V3,
                        },
                        {
                          path: "nadani",
                          name: "ProvidedSupports_V3",
                          component: ProvidedSupports_V3,
                        },
                        {
                          path: "otrimani",
                          name: "ReceivedSupports_V3",
                          component: ReceivedSupports_V3,
                        },
                      ],
                    },
                    {
                      path: "vkladi-u-spilnotu",
                      name: "ContributionsPage_V3",
                      component: ContributionsPage_V3,
                    },
                    {
                      path: "donati",
                      name: "CharitiesPage_V3",
                      component: CharitiesPage_V3,
                    },
                    {
                      path: "vzayemodiyi",
                      name: "OpportunitiesPage_V3",
                      component: OpportunitiesPage_V3,
                    },
                  ],
                },
                {
                  path: "dani",
                  name: "DataPage_V3",
                  redirect: { name: "GroupsPage_V3" },
                  component: DataPage_V3,
                  children: [
                    {
                      path: "grupi",
                      name: "GroupsPage_V3",
                      component: GroupsPage_V3,
                    },
                    {
                      path: "segmenti",
                      name: "SegmentsPage_V3",
                      component: SegmentsPage_V3,
                    },
                    {
                      path: "chati",
                      name: "ChatsPage_V3",
                      component: ChatsPage_V3,
                    },
                    {
                      path: "video",
                      name: "VideosPage_V3",
                      component: VideosPage_V3,
                      redirect: { name: "CreatorsVideosPage_V3" },
                      children: [
                        {
                          path: "ye-veduchim",
                          name: "CreatorsVideosPage_V3",
                          component: CreatorsVideosPage_V3,
                        },
                        {
                          path: "vidimist",
                          name: "VisibilityVideosPage_V3",
                          component: VisibilityVideosPage_V3,
                        },
                      ],
                    },
                    {
                      path: "spivpracya",
                      name: "CollaborationsPage_V3",
                      component: CollaborationsPage_V3,
                      redirect: { name: "CreatorsCollaborationsPage_V3" },
                      children: [
                        {
                          path: "nadani",
                          name: "CreatorsCollaborationsPage_V3",
                          component: CreatorsCollaborationsPage_V3,
                        },
                        {
                          path: "vidimist",
                          name: "VisibilityCollaborationsPage_V3",
                          component: VisibilityCollaborationsPage_V3,
                        },
                      ],
                    },
                    {
                      path: "rekomendaciyi",
                      name: "RecommendationsPage_V3",
                      component: RecommendationsPage_V3,
                      redirect: { name: "CreatorsRecommendationsPage_V3" },
                      children: [
                        {
                          path: "nadani",
                          name: "CreatorsRecommendationsPage_V3",
                          component: CreatorsRecommendationsPage_V3,
                        },
                        {
                          path: "vidimist",
                          name: "VisibilityRecommendationsPage_V3",
                          component: VisibilityRecommendationsPage_V3,
                        },
                      ],
                    },
                    {
                      path: "ekspertizi",
                      name: "ExpertisesPage_V3",
                      component: ExpertisesPage_V3,
                    },
                  ],
                },
                {
                  path: "procesi",
                  name: "ProcessesPage_V3",
                  component: ProcessesPage_V3,
                },
                {
                  path: "analitika",
                  name: "AnalyticsPage_V3",
                  redirect: { name: "EngagementPage_V3" },
                  component: AnalyticsPage_V3,
                  children: [
                    {
                      path: "zaluchenist",
                      name: "EngagementPage_V3",
                      component: EngagementPage_V3,
                      redirect: { name: "Analytic_GeneralPage_V3" },
                      children: [
                        {
                          path: "oglyad",
                          name: "Analytic_GeneralPage_V3",
                          component: Analytic_GeneralPage_V3,
                        },
                        {
                          path: "programa-klubu",
                          name: "Analytic_EventsPage_V3",
                          component: Analytic_EventsPage_V3,
                        },
                        {
                          path: "vzayemodopomoga",
                          name: "Analytic_SupportsPage_V3",
                          component: Analytic_SupportsPage_V3,
                        },
                        {
                          path: "vklad-u-spilnotu",
                          name: "Analytic_ContributionsPage_V3",
                          component: Analytic_ContributionsPage_V3,
                        },
                        {
                          path: "impakt",
                          name: "Analytic_CharitiesPage_V3",
                          component: Analytic_CharitiesPage_V3,
                        },
                        {
                          path: "vzayemodiyi",
                          name: "Analytic_OpportunitiesPage_V3",
                          component: Analytic_OpportunitiesPage_V3,
                        },
                      ],
                    },
                    {
                      path: "vidviduvannya-zustrichej",
                      name: "VisitedEventsPage_V3",
                      component: VisitedEventsPage_V3,
                    },
                    {
                      path: "zastosunok",
                      name: "ApplicationPage_V3",
                      component: ApplicationPage_V3,
                      redirect: { name: "LoginsPage_V3" },
                      children: [
                        {
                          path: "seansi",
                          name: "LoginsPage_V3",
                          component: LoginsPage_V3,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "oglyad",
                  name: "InfoPage_V3",
                  redirect: { name: "PrivatePage_V3" },
                  component: InfoPage_V3,
                  children: [
                    {
                      path: "vnutrishnij-profil",
                      name: "PrivatePage_V3",
                      component: PrivatePage_V3,
                    },
                    {
                      path: "zovnishnij-profil",
                      name: "PublicPage_V3",
                      component: PublicPage_V3,
                    },
                    {
                      path: "istoriya-chlenstva",
                      name: "HistoryPage_V3",
                      component: HistoryPage_V3,
                    },
                  ],
                },
              ],
            },
            {
              path: "zustrichi/:id?",
              name: "EventProfilePage_V3",
              redirect: { name: "InvitesPage_V3" },
              component: EventProfilePage_V3,
              children: [
                {
                  path: "reyestraciyi",
                  name: "InvitesPage_V3",
                  component: InvitesPage_V3,
                  redirect: { name: "UsersListPage_V3" },
                  children: [
                    {
                      path: "uchasniki",
                      name: "UsersListPage_V3",
                      component: UsersListPage_V3,
                    },
                    {
                      path: "gosti",
                      name: "GuestsListPage_V3",
                      component: GuestsListPage_V3,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "data",
          redirect: { name: "UsersPage", params: { id: "active" } },
          name: "DataPage",
          component: DataPage,
          children: [
            {
              path: "profiles/:id?",
              name: "UsersPage",
              component: UsersPage,
            },
            {
              path: "guests/:id?",
              name: "GuestsPage",
              component: GuestsPage,
            },
            {
              path: "groups",
              name: "GroupsPage",
              component: GroupsPage,
            },
            {
              path: "segments",
              name: "SegmentsPage",
              component: SegmentsPage,
            },
            {
              path: "events/:id?",
              name: "EventsPage",
              component: EventsPage,
            },
            {
              path: "chats",
              name: "ChatsPage",
              component: ChatsPage,
            },
            {
              path: "videos",
              name: "VideosPage",
              component: VideosPage,
            },
            {
              path: "expert",
              name: "ExpertsPage",
              component: ExpertsPage,
            },
            {
              path: "collaborations",
              name: "CollaborationsPage",
              component: CollaborationsPage,
            },
            {
              path: "recommendations",
              name: "RecommendationsPage",
              component: RecommendationsPage,
            },
            {
              path: "codex",
              name: "CodexPage",
              component: CodexPage,
            },
            {
              path: "initiative/:id?",
              name: "InitiativePage",
              component: InitiativePage,
            },
            {
              path: "expertises",
              name: "ExpertisesPage",
              component: ExpertisesPage,
            },
          ],
        },
        {
          path: "activity",
          redirect: {
            name: "RegistrationsPage",
            params: { id: "registration_is_open" },
          },
          name: "ActivityPage",
          component: ActivityPage,
          children: [
            {
              path: "registrations/:id?",
              name: "RegistrationsPage",
              component: RegistrationsPage,
            },
            {
              path: "users",
              redirect: { name: "RegistrationsPage" },
              name: "TasksPage",
              component: TasksPage,
            },
            {
              path: "contributions",
              name: "ContributionsPage",
              component: ContributionsPage,
            },
            {
              path: "charity/:id?",
              name: "CharitysPage",
              component: CharitysPage,
            },
            {
              path: "supports",
              name: "SupportsPage",
              component: SupportsPage,
            },
            {
              path: "opportunities",
              name: "PossibilitysPage",
              component: PossibilitysPage,
            },
          ],
        },
        {
          path: "analytics",
          redirect: { name: "ActivityPage_Analytics" },
          name: "AnalyticsPage",
          component: AnalyticsPage,
          children: [
            {
              path: "activity",
              name: "ActivityPage_Analytics",
              component: ActivityPage_Analytics,
            },
            {
              path: "attendance_group",
              name: "AttendanceGroupPage",
              component: AttendanceGroupPage,
            },
            {
              path: "engagement",
              name: "InvolvementPage",
              component: InvolvementPage,
            },
            {
              path: "attendance/:id?",
              name: "AttendancePage",
              component: AttendancePage,
            },
            {
              path: "contributions",
              name: "AnalyticContributionsPage",
              component: AnalyticContributionsPage,
            },
            {
              path: "supports/:id?",
              name: "AnalyticSupportsPage",
              component: AnalyticSupportsPage,
            },
            {
              path: "charity",
              name: "AnalyticCharitysPage",
              component: AnalyticCharitysPage,
            },
            {
              path: "opportunities",
              name: "AnalyticPossibilitysPage",
              component: AnalyticPossibilitysPage,
            },
            {
              path: "participant_flow",
              name: "ParticipantFlowPage",
              component: ParticipantFlowPage,
            },
          ],
        },
        {
          path: "favorites",
          name: "FavoritesPage",
          component: FavoritesPage,
          children: [
            {
              path: "profiles/:id?",
              name: "UsersPage_Favorite",
              component: UsersPage,
            },
            {
              path: "groups/:id?",
              name: "GroupsPage_Favorite",
              component: GroupsPage,
            },
            {
              path: "segments",
              name: "SegmentsPage_Favorite",
              component: SegmentsPage,
            },
            {
              path: "events/:id?",
              name: "EventsPage_Favorite",
              component: EventsPage,
            },
            {
              path: "chats",
              name: "ChatsPage_Favorite",
              component: ChatsPage,
            },
            {
              path: "videos",
              name: "VideosPage_Favorite",
              component: VideosPage,
            },
            {
              path: "expert",
              name: "ExpertsPage_Favorite",
              component: ExpertsPage,
            },
            {
              path: "collaborations",
              name: "CollaborationsPage_Favorite",
              component: CollaborationsPage,
            },
            {
              path: "recommendations",
              name: "RecommendationsPage_Favorite",
              component: RecommendationsPage,
            },
            {
              path: "codex",
              name: "CodexPage_Favorite",
              component: CodexPage,
            },
            {
              path: "initiative/:id?",
              name: "InitiativePage_Favorite",
              component: InitiativePage,
            },
            {
              path: "registrations/:id?",
              name: "RegistrationsPage_Favorite",
              component: RegistrationsPage,
            },
            {
              path: "contributions",
              name: "ContributionsPage_Favorite",
              component: ContributionsPage,
            },
            {
              path: "charity/:id?",
              name: "CharitysPage_Favorite",
              component: CharitysPage,
            },
            {
              path: "supports",
              name: "SupportsPage_Favorite",
              component: SupportsPage,
            },
            {
              path: "opportunities",
              name: "PossibilitysPage_Favorite",
              component: PossibilitysPage,
            },
            {
              path: "activity",
              name: "ActivityPage_Analytics_Favorite",
              component: ActivityPage_Analytics,
            },
            {
              path: "attendance/:id?",
              name: "AttendancePage_Favorite",
              component: AttendancePage,
            },
            {
              path: "attendance_group",
              name: "AttendanceGroupPage_Favorite",
              component: AttendanceGroupPage,
            },
            {
              path: "engagement",
              name: "InvolvementPage_Favorite",
              component: InvolvementPage,
            },
            {
              path: "contributions",
              name: "AnalyticContributionsPage_Favorite",
              component: AnalyticContributionsPage,
            },
            {
              path: "supports/:id?",
              name: "AnalyticSupportsPage_Favorite",
              component: AnalyticSupportsPage,
            },
            {
              path: "charity",
              name: "AnalyticCharitysPage_Favorite",
              component: AnalyticCharitysPage,
            },
            {
              path: "possibility",
              name: "AnalyticPossibilitysPage_Favorite",
              component: AnalyticPossibilitysPage,
            },
            {
              path: "participant_flow",
              name: "ParticipantFlowPage_Favorite",
              component: ParticipantFlowPage,
            },
          ],
        },
        {
          path: "processes",
          name: "ProcessesPage",
          component: ProcessesPage,
          children: [
            {
              path: ":id?",
              name: "ProcessPage",
              component: ProcessPage,
            },
          ],
        },
        {
          path: "integrations",
          name: "IntegrationsPage",
          component: IntegrationsPage,
        },
        {
          path: "home",
          name: "HomePage",
          component: HomePage,
        },
      ],
    },
    {
      path: "/login",
      name: "LogInPage",
      component: LogInPage,
    },
    {
      path: "/account/reset_password/:id?",
      name: "ResetPasswordPage",
      component: ResetPasswordPage,
    },
    {
      path: "/account/registration_manager/:id?",
      name: "RegistrationManagerPage",
      component: RegistrationManagerPage,
    },
    {
      path: "/layouts",
      name: "LayoutPage",
      component: LayoutPage,
    },
  ],
});
